import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "AUS-NET-FST";

export const foster:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "AusNet",
    hub_location: new CosecGeoPoint(-38.58367084685425, 146.29642067366945),
    hub_geo_features: [],
    devices: [
        {name: `${name_prefix}-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5841646, 146.2903814, 83.8), date_install: new Date("2024-12-06T08:00:00.000+11:00") },
        {name: `${name_prefix}-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5842551, 146.2900024, 83.8), date_install: new Date("2024-12-06T08:00:00.000+11:00") },
        {name: `${name_prefix}-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.584336, 146.2897035, 83.8), date_install: new Date("2024-12-06T14:00:00.000+11:00") },
        {name: `${name_prefix}-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5844237, 146.2893243, 83.8), date_install: new Date("2024-12-06T14:00:00.000+11:00") },
        {name: `${name_prefix}-5`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5845019, 146.288985, 83.8), date_install: new Date("2024-12-06T14:00:00.000+11:00") },
        {name: `${name_prefix}-6`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5845791, 146.2886681, 83.8), date_install: new Date("2024-12-06T14:00:00.000+11:00") },
        {name: `${name_prefix}-7`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5846475, 146.2883804, 83.8), date_install: new Date("2024-12-06T14:00:00.000+11:00") },
    ],
}
