import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "PWR-LNK-SBK";

export const swanbank:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "Powerlink",
    hub_location: new CosecGeoPoint(-27.6522846208454, 152.8123228950438),
    hub_geo_features: [],
    devices: [
        {name: name_prefix + "-P2-1/2-1", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.65322119,152.8131521, 83.8), date_install: new Date("2024-12-16T08:45:00.000+10:00") },
        {name: name_prefix + "-P2-1/2-2", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.65293669,152.8131693, 83.8), date_install: new Date("2024-12-16T08:45:00.000+10:00") },
    ],
}
