import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "AUS-NET-BXT";

export const buxton:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "AusNet",
    hub_location: new CosecGeoPoint(-37.4168398,145.7257058),
    hub_geo_features: [],
    devices: [
        {name: `${name_prefix}-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4162142, 145.725844, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.416319, 145.7264833, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4164016, 145.7268096, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4162954, 145.726152, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-5`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4164286, 145.7271536, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-8`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4166201, 145.7281426, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-6`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4165137, 145.7274841, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
        {name: `${name_prefix}-7`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-37.4165399, 145.7278238, 83.8), date_install: new Date("2024-12-05T10:30:00.000+11:00") },
    ],
}
