/*
 *  ============================================================================================
 *  Mock Database Interface
 *  ============================================================================================
 */
import { CosecGeoPoint } from "./common";
import { DATASET_ID, DBTools, InterfaceHubGeoFeatures } from "./db_tools";
import { WidgetMinNomMaxType } from "./dom_factory";
import { InterfaceDatabase, InterfaceDeviceSensor, InterfaceDeviceKeys, InterfaceHub, InterfaceHubKeys, InterfaceReading, InterfaceReadingThresholds, InterfaceUserProfile, InterfaceDeviceType, InterfaceDeviceBase, InterfaceDeviceStatic } from "./interface_database";
import { HubDeviceLayout, HubDeviceSetups } from "./interface_devices";
import { manual_installs } from "./manual_installs/_manual_installs";

const MAX_READINGS_PER_DEVICE=2000;

interface DatabaseMockUserMetadata {
    createdAt:Date;
}

class DatabaseMockUser {
    static ADMIN_UID = "1";

    uid = DatabaseMockUser.ADMIN_UID;

    metadata:DatabaseMockUserMetadata = {
        createdAt: null
    }

    email = "";
    password = "";
    displayName = "Demo User";
    phoneNumber = "88888888";

    constructor(email:string, password:string) {
        this.email = email;
        this.password = password;

        this.metadata.createdAt = new Date();
    }
}

class DatabaseMockReading implements InterfaceReading {
    #id:string;

    ambience = {
        humidity: 0.0,
        pressure: 0.0,
        temperature: 0.0,
    }

    info = {
        features: {
            ANGLE: false,
            DISTANCE: false,
            HUMIDITY: false,
            IDENTIFY: false,
            PRESSURE: false,
            RELEASE: false,
            SOLAR_V: false,
            TEMPERATURE: false,
            VOLTAGE: false
        },
        identify: false,
        msg_type: 202,
        release: false,
        timestamp: DBTools.date_invalid,
        uuid: ""
    }

    power = {
        voltage_batt: 0.0,
        voltage_solar: 0.0
    }

    range = {
        angle: 0.0,
        distance_adj: 0.0,
        distance_raw: 0.0
    }

    constructor(device_uuid:string = "", timestamp = DBTools.date_invalid, do_generate=false) {
        this.#id = null;
        this.info.uuid = device_uuid;
        this.info.timestamp = timestamp;

        if(do_generate)
            this.generate_data(timestamp, false);
    }

    static from_data(data:InterfaceReading) {
        let reading = new DatabaseMockReading();
        reading.info = data.info;
        reading.ambience = data.ambience;
        reading.power = data.power;
        reading.range = data.range;
        return reading;
    }

    id() {
        return this.#id;
    }

    set_id(id:string) {
        this.#id = id;
    }

    generate_data(timestamp = DBTools.date_invalid, override_created_timestamp=true) {
        const date_now = new Date();
        if(timestamp == DBTools.date_invalid)
            timestamp = date_now;

        const time_now = timestamp.valueOf();
        const MS_PER_SECOND = 1000;
        const MS_PER_MINUTE = 60*MS_PER_SECOND;
        const MS_PER_HOUR = 60*MS_PER_MINUTE;
        const MS_PER_DAY = 24*MS_PER_HOUR;
        const MS_PER_WEEK = 7*MS_PER_DAY;

        //Offset for how far past UTC midnight the local midnight is
        const tz_offset = (-date_now.getTimezoneOffset()*MS_PER_MINUTE) / MS_PER_DAY;
        //Offset for how far past local midnight it is currently
        const daily_offset = (time_now % MS_PER_DAY) / MS_PER_DAY;
        //Offset for how far through the week we are
        const weekly_offset = (time_now % MS_PER_WEEK) / MS_PER_WEEK;
        //Offset for how far past local midnight it is before dawn breaks (in hours, e.g. 4am)
        const dawn_offset = (4*MS_PER_HOUR) / MS_PER_DAY;
        //Offset for how far past local dawn it is before real sunlight breaks
        const sunlight_offset = (2*MS_PER_HOUR) / MS_PER_DAY;

        //Temperature is lowest at dawn,
        //and max at afternoon,
        //ranging between +- 1/2 temp_diff,
        //with a min of temp_min and slight variation
        const temp_min = 16.0;
        const temp_max = 28.0;
        const temp_var = 1.0;
        const temp_diff = temp_max - temp_min;
        this.ambience.temperature = -0.5*temp_diff*Math.cos(2*Math.PI*(daily_offset+tz_offset-dawn_offset))
                                    + 0.5*temp_diff
                                    + temp_min
                                    + (temp_var*Math.random() - temp_var/2);

        //Humid is highest at dawn,
        //and lowest at afternoon,
        //ranging between +- 1/2 humid_diff,
        //with a min of humid_min and slight variation,
        //and with some rain every few days and a lock to stop at 100%
        const humid_min = 0.75;
        const humid_max = 1.0;
        const humid_var = 0.01;
        const humid_diff = humid_max - humid_min;
        this.ambience.humidity = 100*(
                                    Math.min(humid_max,
                                    0.25*humid_diff*Math.cos(2*Math.PI*(daily_offset+tz_offset-dawn_offset))
                                    - 0.25*humid_diff*Math.cos(2*Math.PI*(weekly_offset/2))
                                    + humid_min
                                    + (humid_var*Math.random() - humid_var/2))
                                 ); //*100 to be in normal decimal range

        //Pressure is driven by clouds rolling through every few hours
        this.ambience.pressure = Math.random();
        const pressure_min = 1020;
        const pressure_max = 1024;
        const pressure_var = 0.2;
        const pressure_diff = pressure_max - pressure_min;
        this.ambience.pressure = -0.5*pressure_diff*Math.sin(3*Math.PI*(daily_offset))
                                 + 0.5*pressure_diff
                                 + pressure_min
                                 + (pressure_var*Math.random() - pressure_var/2);

        //Battery voltage rolls with sunlight, with a slight delay from dawn
        const batt_min = 3.6;
        const batt_max = 4.0;
        const batt_var = 0.1;
        const batt_diff = batt_max - batt_min;
        this.power.voltage_batt = -0.5*batt_diff*Math.cos(2*Math.PI*(daily_offset+tz_offset-dawn_offset-sunlight_offset))
                                  + 0.5*batt_diff
                                    + batt_min
                                    + (batt_var*Math.random() - batt_var/2);

        //Solar is a near square wave that happens after a slight delay after sunrise
        const solar_min = -20;
        const solar_max = 20;
        const solar_var = 0.2;
        const solar_diff = solar_min - solar_max;
        this.power.voltage_solar = Math.max(0, Math.min(batt_max,
                                    0.5*solar_diff*Math.cos(2*Math.PI*(daily_offset+tz_offset))
                                  ) + (solar_var*Math.random() - solar_var/2));

        //Angle is relatively random within a few degrees due to low wind and large gusts
        const angle_min = -1.5;
        const angle_max = 1.5;
        const angle_var = 1;
        const angle_diff = angle_max - angle_min;
        this.range.angle = -0.5*angle_diff*Math.cos(2*Math.PI*daily_offset/8)
                                    + 0.5*angle_diff
                                    + (angle_var*Math.random() - angle_var/2);

        //Distance adjusted is the calculated height above ground
        //and is subject to peak and off-peak loads
        const dist_min = 9.0;
        const dist_max = 10.0;
        const dist_var = 0.05;
        const dist_diff = dist_max - dist_min;
        this.range.distance_adj = -0.5*dist_diff*Math.cos(2*Math.PI*2*(daily_offset+tz_offset-dawn_offset-sunlight_offset))
                                  + 0.5*dist_diff
                                  + dist_min
                                  + (dist_var*Math.random() - dist_var/2);

        //Distance raw is the range reading "this.range.distance_adj"
        //at angle reading "this.range.angle", so we work it out backwards instead
        this.range.distance_raw = this.range.distance_adj/Math.cos(this.range.distance_adj*Math.PI/180);

        if(override_created_timestamp)
            this.info.timestamp = timestamp;
    }
}

class DatabaseMockDeviceGeneric implements InterfaceDeviceBase {
    #id:string;
    #data_watchers;

    // wire_length = 110;          //m
    // span_length = 100;          //m
    // span_height_origin = 20;    //m (Pa height above ground)
    // span_height_delta = 1;      //m (Pb height above Pa)
    // span_fixing = 50;          //m (Device fixing distance from Pa)

    type = InterfaceDeviceType.GENERIC;

    archived = false;

    timestamp = new Date();
    uuid = DBTools.generate_hex_key();

    location = new CosecGeoPoint();
    name = 'Device #' + DBTools.generate_uid().toString().toUpperCase();

    constructor(name="", location = new CosecGeoPoint()) {
        this.#id = null;
        this.#data_watchers = new Map();
        this.location = location;
        if(name)
            this.name = name;
    }

    static from_data(data:InterfaceDeviceBase) {
        let device = new DatabaseMockDeviceGeneric();
        device.archived = data.archived;
        device.timestamp = data.timestamp;
        device.uuid = data.uuid;
        device.location = data.location;
        device.name = data.name;
        return device;
    }

    id() {
        return this.#id;
    }

    set_id(id:string) {
        this.#id = id;
    }

    register_watch_data(callback:CallableFunction) {
        const cb_id = DBTools.generate_hex_key();
        this.#data_watchers.set(cb_id, callback)
        return this.deregister_watch_data.bind(this, cb_id);
    }

    deregister_watch_data(cb_id:string) {
        let success = false;

        if(this.#data_watchers.has(cb_id)) {
            this.#data_watchers.delete(cb_id);
            success = true;
        }

        return success;
    }

    on_data_change() {
        for(const cb of this.#data_watchers.values())
            cb(this.#id, this);
    }
}

class DatabaseMockDeviceStatic implements InterfaceDeviceStatic {
    #id:string;
    #data_watchers;

    type = InterfaceDeviceType.GENERIC;

    archived = false;
    timestamp = new Date();
    uuid = DBTools.generate_hex_key();

    location = new CosecGeoPoint();
    name = 'Device #' + DBTools.generate_uid().toString().toUpperCase();

    date_install = new Date();
    date_review = new Date();
    review = 0;
    warranty = 0;
    renewal = 0;
    lifespan = 0;

    constructor(type:InterfaceDeviceType, name="", location = new CosecGeoPoint()) {
        this.#id = null;
        this.#data_watchers = new Map();
        this.location = location;
        this.type = type;
        if(name)
            this.name = name;
    }

    static from_data(data:InterfaceDeviceStatic) {
        let device = new DatabaseMockDeviceStatic(data.type);
        device.archived = data.archived;
        device.timestamp = data.timestamp;
        device.uuid = data.uuid;
        device.location = data.location;
        device.name = data.name;
        device.date_install = data.date_install;
        device.date_review = data.date_review;
        device.review = data.review;
        device.warranty = data.warranty;
        device.renewal = data.renewal;
        return device;
    }

    id() {
        return this.#id;
    }

    set_id(id:string) {
        this.#id = id;
    }


    set_date_install(date_install:Date) {
        this.date_install = date_install;
        this.on_data_change()
    }

    set_date_review(date_review:Date) {
        this.date_review = date_review;
        this.on_data_change()
    }

    set_review(review:number) {
        this.review = review;
        this.on_data_change()
    }

    set_warranty(warranty:number) {
        this.warranty = warranty;
        this.on_data_change()
    }

    set_renewal(renewal:number) {
        this.renewal = renewal;
        this.on_data_change()
    }


    set_lifespan(lifespan:number) {
        this.lifespan = lifespan;
        this.on_data_change();
    }

    register_watch_data(callback:CallableFunction) {
        const cb_id = DBTools.generate_hex_key();
        this.#data_watchers.set(cb_id, callback)
        return this.deregister_watch_data.bind(this, cb_id);
    }

    deregister_watch_data(cb_id:string) {
        let success = false;

        if(this.#data_watchers.has(cb_id)) {
            this.#data_watchers.delete(cb_id);
            success = true;
        }

        return success;
    }

    on_data_change() {
        for(const cb of this.#data_watchers.values())
            cb(this.#id, this);
    }
}


class DatabaseMockDeviceSensor implements InterfaceDeviceSensor {
    #id:string;
    #readings:Map<string,DatabaseMockReading>;
    #data_watchers;
    #readings_watchers;
    #data_gen_timer;

    // wire_length = 110;          //m
    // span_length = 100;          //m
    // span_height_origin = 20;    //m (Pa height above ground)
    // span_height_delta = 1;      //m (Pb height above Pa)
    // span_fixing = 50;          //m (Device fixing distance from Pa)

    type = InterfaceDeviceType.ROTASENSOR;

    identify = false;
    release = false;
    archived = false;

    date_boot = new Date();
    date_contacted = new Date();
    timestamp = new Date();
    uuid = DBTools.generate_hex_key();

    location = new CosecGeoPoint();
    name = 'Device #' + DBTools.generate_uid().toString().toUpperCase();
    msg_type = 0;

    thresholds:InterfaceReadingThresholds = null;

    features = {
        ANGLE: true,
        DISTANCE: true,
        HUMIDITY: true,
        IDENTIFY: true,
        PRESSURE: true,
        RELEASE: true,
        SOLAR_V: false,
        TEMPERATURE: true,
        VOLTAGE: false
    }

    constructor(name="", location = new CosecGeoPoint(), num_readings=0, new_data_interval_ms=0, gen_random_thresholds=true) {
        this.#id = null;
        this.#data_watchers = new Map();
        this.#readings_watchers = new Map();
        this.#readings = new Map();
        this.location = location;
        if(name)
            this.name = name;
        this.generate_bulk_data(num_readings, new_data_interval_ms);
        this.#data_gen_timer = new_data_interval_ms ? setInterval(this.generate_datapoint.bind(this), new_data_interval_ms) : null;
        if(gen_random_thresholds)
            this.generate_random_thresholds();
    }

    generate_random_thresholds() {
        //TODO: Make more random
        this.thresholds = {
            max: {
                info: null,
                ambience: {
                    temperature: Math.random() > 0.5 ? 15.0 : null,
                    pressure: null,
                    humidity: null,
                },
                power: {
                    voltage_batt: null,
                    voltage_solar: null,
                },
                range: {
                    angle: null,
                    distance_adj: Math.random() > 0.75 ? 5.0 : null,
                    distance_raw: null,
                }
            }
        };
    }

    static from_data(data:InterfaceDeviceSensor) {
        let device = new DatabaseMockDeviceSensor();
        device.identify = data.identify;
        device.release = data.release;
        device.archived = data.archived;
        device.date_boot = data.date_boot;
        device.date_contacted = data.date_contacted;
        device.timestamp = data.timestamp;
        device.uuid = data.uuid;
        device.location = data.location;
        device.name = data.name;
        device.msg_type = data.msg_type;
        device.features = data.features;
        return device;
    }

    id() {
        return this.#id;
    }

    set_id(id:string) {
        this.#id = id;
    }

    set_date_contacted(timestamp = new Date()) {
        this.date_contacted = timestamp;
        this.on_data_change();
    }

    get_readings() {
        return this.#readings;
    }

    add_reading(reading_data:InterfaceReading, trigger_callbacks=true, trim_readings=MAX_READINGS_PER_DEVICE) {
        const id = DBTools.generate_uid() + DBTools.generate_uid();

        if(reading_data instanceof DatabaseMockReading) {
            this.#readings.set(id, reading_data);
        } else {
            const device = DatabaseMockReading.from_data(reading_data);
            this.#readings.set(id, device);
        }

        if(trim_readings && (this.#readings.size > trim_readings)) {
            let rdb_asc = [...this.#readings].sort(
                (a, b) => (a[1].info.timestamp < b[1].info.timestamp) ? 1 : -1
            );
            this.#readings = new Map(rdb_asc.slice(0, trim_readings));
        }

        if(trigger_callbacks)
            this.on_readings_change();
        return id;
    }

    generate_bulk_data(num_readings = 0, step_ms = 1000) {
        const time_now = (new Date()).valueOf();
        for(let i=num_readings; i >= 1; i--) {
            let timestamp = new Date(time_now - (i-1)*step_ms);
            let reading = new DatabaseMockReading(this.id(), timestamp, true);
            reading.set_id(this.add_reading(reading, false));
        }
        //XXX: trigger callback in bulk after all readings are added
        this.on_readings_change();
    }

    generate_datapoint() {
        try{
            const date_now = new Date();
            let reading = new DatabaseMockReading(this.id(), date_now, true);
            reading.set_id(this.add_reading(reading));

            this.set_date_contacted(date_now);
        }
        catch(err) {
            console.warn("Failed to generate new datapoint, cancelling timer");
            if(this.#data_gen_timer)
                clearInterval(this.#data_gen_timer);
        }
    }

    register_watch_data(callback:CallableFunction) {
        const cb_id = DBTools.generate_hex_key();
        this.#data_watchers.set(cb_id, callback)
        return this.deregister_watch_data.bind(this, cb_id);
    }

    deregister_watch_data(cb_id:string) {
        let success = false;

        if(this.#data_watchers.has(cb_id)) {
            this.#data_watchers.delete(cb_id);
            success = true;
        }

        return success;
    }

    register_watch_readings(callback:CallableFunction) {
        const cb_id = DBTools.generate_hex_key();
        this.#readings_watchers.set(cb_id, callback)
        return this.deregister_watch_readings.bind(this, cb_id);
    }

    deregister_watch_readings(cb_id:string) {
        let success = false;

        if(this.#readings_watchers.has(cb_id)) {
            this.#readings_watchers.delete(cb_id);
            success = true;
        }

        return success;
    }

    on_data_change() {
        for(const cb of this.#data_watchers.values())
            cb(this.#id, this);
    }

    on_readings_change() {
        for(const cb of this.#readings_watchers.values())
            cb();
    }
}

class DatabaseMockHub implements InterfaceHub {
    #id:string;
    #data_watchers;
    #device_watchers;
    #devices:Map<string,DatabaseMockDeviceGeneric|DatabaseMockDeviceStatic|DatabaseMockDeviceSensor>;

    admin = DatabaseMockUser.ADMIN_UID;
    users:string[] = [];
    owner = 'V-TOL Aerospace';
    name = 'Hub #' + DBTools.generate_uid().toString().toUpperCase();

    activation_secret = DBTools.generate_hex_key();
    archived = false;
    identify = false;
    location = new CosecGeoPoint();

    date_allocated = new Date();
    date_activated = new Date();
    date_boot = new Date();
    date_contacted = new Date();
    date_updated = new Date();

    features = {
        GNET: true,
        MESH: true,
        OTA_UPDATE: true,
        USER_IO: true,
        WIFI: true,
    }

    hardware = {
        model: "v1.0",
        uuid: DBTools.generate_hex_key(),
    }

    geo_features:InterfaceHubGeoFeatures[] = [];

    constructor(location = new CosecGeoPoint(), device_layouts:HubDeviceSetups[] = [], name = "", owner = "") {
        this.#id = null;
        this.#data_watchers = new Map();
        this.#device_watchers = new Map();
        this.#devices = new Map();
        this.location = location;
        if(name)
            this.name = name
        if(owner)
            this.owner = owner;

        this.generate_data(device_layouts);
    }

    static from_data(data:InterfaceHub) {
        let hub = new DatabaseMockHub();
        hub.admin = data.admin;
        hub.users = data.users;
        hub.owner = data.owner;
        hub.name = data.name;
        hub.activation_secret = data.activation_secret;
        hub.archived = data.archived;
        hub.identify = data.identify;
        hub.location = data.location;
        hub.date_allocated = data.date_allocated;
        hub.date_activated = data.date_activated;
        hub.date_boot = data.date_boot;
        hub.date_contacted = data.date_contacted;
        hub.date_updated = data.date_updated;
        hub.features = data.features;
        hub.hardware = data.hardware;
        return hub;
    }

    id() {
        return this.#id;
    }

    set_id(id:string) {
        this.#id = id;
    }

    generate_data(device_layouts:HubDeviceSetups[] = []) {
        const MS_PER_MINUTE = 60000;
        const step_fine = 5.0 * MS_PER_MINUTE;
        const step_medium = 3 * 60 * MS_PER_MINUTE;
        const step_course = 0.5 * 24 * 60 * MS_PER_MINUTE;
        const now = new Date();

        const n1 = Math.round(Math.random()*999);
        const n1s = n1.toString().padStart(4, '0');
        const n2 = Math.round(Math.random()*99);
        const n_offset = Math.round(Math.random()*5);
        let i = 0;
        for(const device of device_layouts) {
            // const lifespan = Math.round(Math.random()*9999999/2);
            const n2i = Math.round(n2 + n_offset*i);
            const name = device.name ? device.name : this.name + '-' + n1s + '-' + n2i.toString().padStart(4, '0');
            let dev = null;
            switch(device.type){
                case InterfaceDeviceType.ROTAFLAG: {
                    dev = new DatabaseMockDeviceStatic(device.type, name, device.location);
                    dev.set_date_install(device.date_install ? device.date_install : now);
                    dev.set_date_review(device.date_review ? device.date_review : now);
                    dev.set_review(365);
                    dev.set_warranty(3*365);
                    dev.set_renewal(5*365);
                    dev.set_lifespan(10*365);
                    break;
                }
                case InterfaceDeviceType.ROTAMARKER_BW: {
                    dev = new DatabaseMockDeviceStatic(device.type, name, device.location);
                    dev.set_date_install(device.date_install ? device.date_install : now);
                    dev.set_date_review(device.date_review ? device.date_review : now);
                    dev.set_review(365);
                    dev.set_warranty(3*365);
                    dev.set_renewal(5*365);
                    dev.set_lifespan(10*365);

                    break;
                }
                case InterfaceDeviceType.ROTAMARKER_RW: {
                    dev = new DatabaseMockDeviceStatic(device.type, name, device.location);
                    dev.set_date_install(device.date_install ? device.date_install : now);
                    dev.set_date_review(device.date_review ? device.date_review : now);
                    dev.set_review(365);
                    dev.set_warranty(3*365);
                    dev.set_renewal(5*365);
                    dev.set_lifespan(10*365);

                    break;
                }
                case InterfaceDeviceType.ROTASENSOR: {
                    dev = new DatabaseMockDeviceSensor(name, device.location);
                    //XXX: Do this afterwards to make sure ID is sync'd to readings (maybe not needed, but good to do)
                    dev.generate_bulk_data(300, step_fine);     //~ 1 day
                    dev.generate_bulk_data(900, step_medium);   //~ 10 months
                    dev.generate_bulk_data(300, step_course);   //~ 1.2 years
                    break;
                }
                default: {
                    dev = new DatabaseMockDeviceGeneric(name, device.location);
                    break;
                }
            }

            if(dev != null) {
                dev.set_id(this.add_device(dev, false));
            }

            i++;
        }
        //XXX: Do this in bulk after all devices are added
        this.on_devices_change();
    }

    get_device_ids() {
        return [...this.#devices.keys()];
    }

    add_device(device_data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor, trigger_callbacks=true) {
        const id = DBTools.generate_uid() + DBTools.generate_uid();
        if(device_data instanceof DatabaseMockDeviceSensor) {
            this.#devices.set(id, device_data);
        } else {
            let device = null;
            switch(device_data.type) {
                case InterfaceDeviceType.ROTAFLAG: {
                    device = DatabaseMockDeviceStatic.from_data(device_data as InterfaceDeviceStatic);
                    break;
                }
                case InterfaceDeviceType.ROTAMARKER_BW: {
                    device = DatabaseMockDeviceStatic.from_data(device_data as InterfaceDeviceStatic);
                    break;
                }
                case InterfaceDeviceType.ROTAMARKER_RW: {
                    device = DatabaseMockDeviceStatic.from_data(device_data as InterfaceDeviceStatic);
                    break;
                }
                case InterfaceDeviceType.ROTASENSOR: {
                    device = DatabaseMockDeviceSensor.from_data(device_data as InterfaceDeviceSensor);
                    break;
                }
                default: { //InterfaceDeviceType.GENERIC
                    device = DatabaseMockDeviceGeneric.from_data(device_data as InterfaceDeviceBase);
                    break;
                }
            }

            this.#devices.set(id, device);
        }
        if(trigger_callbacks)
            this.on_devices_change();
        return id;
    }

    get_device(dev_id:string) {
        let dev = null;
        if(this.#devices.has(dev_id))
            dev = this.#devices.get(dev_id);

        return dev;
    }

    register_watch_data(callback:CallableFunction) {
        const cb_id = DBTools.generate_hex_key();
        this.#data_watchers.set(cb_id, callback)
        return this.deregister_watch_data.bind(this, cb_id);
    }

    deregister_watch_data(cb_id:string) {
        let success = false;

        if(this.#data_watchers.has(cb_id)) {
            this.#data_watchers.delete(cb_id);
            success = true;
        }

        return success;
    }

    register_watch_devices(callback:CallableFunction) {
        const cb_id = DBTools.generate_hex_key();
        this.#device_watchers.set(cb_id, callback)
        return this.deregister_watch_devices.bind(this, cb_id);
    }

    deregister_watch_devices(cb_id:string) {
        let success = false;

        if(this.#device_watchers.has(cb_id)) {
            this.#device_watchers.delete(cb_id);
            success = true;
        }

        return success;
    }

    on_data_change() {
        const id = this.#id;
        console.log(`Hub ${id} data updated`);
        for(const cb of this.#data_watchers.values())
            cb(this.#id, this);
    }

    on_devices_change() {
        for(const cb of this.#device_watchers.values())
            cb();
    }
}

export class DatabaseMock implements InterfaceDatabase {
    #current_hub:string;
    #user:DatabaseMockUser;
    #hubs:Map<string,DatabaseMockHub>;

    constructor() {
        this.#current_hub = null;
        this.#user = null;
        this.#hubs = null;

        console.log("Created Mock interface!");
    }

    async user_logout() {
        this.#user = null;
    }

    async user_login(email:string, password:string, login_callback:(success:boolean, failure_reason:string)=>void) {
        login_callback(await this.user_register(email, password), "");
    }

    async user_register(email:string, password:string) {
        let success = false;

        this.#user = new DatabaseMockUser(email, password);
        console.log(`Signed in as user: ${this.#user.uid} (${this.#user.email})`);

        success = true;

        return success;
    }

    load() {
        console.log("Loading from Mock database...");

        this.#hubs = this.#hubs_generate();

        //Wait a touch to simulate "loading"
        DBTools.delay(500);
    }

    // --== User Profile ==--
    get_user_id() {
        return this.#user.uid;
    }

    get_user_profile() {
        let names = [];
        if(this.#user.displayName) {
            names = this.#user.displayName.split(' ');
        } else {
            names.push("Anonymous");
        }

        return <InterfaceUserProfile>{
            "name_first": names[0],
            "name_last": names[names.length - 1],
            "mobile": this.#user.phoneNumber,
            "email": this.#user.email,
            "datetime_created": this.#user.metadata.createdAt,
            "username": this.#user.email
        };
    }

    get_user_real_name() {
        const profile = this.get_user_profile();
        return profile['name_first'] + ' ' + profile['name_last'];
    }

    get_user_real_name_initials() {
        const profile = this.get_user_profile();
        return profile['name_first'][0] + profile['name_last'][0];
    }

    // --== Hub ==--
    async get_hub_ids(get_archived=false) {
        let hubs = [];

        for(const [id, hub] of this.#hubs) {
            if(!hub.archived || (get_archived && hub.archived)) {
                hubs.push(id);
            }
        }

        return hubs;
    }

    async get_default_hub_id() {
        const ids = await this.get_hub_ids();
        return ids[0];
    }

    clear_current_hub() {
        this.#current_hub = null;
    }

    async set_current_hub(id:string) {
        const ids = await this.get_hub_ids();
        if(ids.includes(id)) {
            this.#current_hub = id;
        } else {
            throw new Error('Hub ID is not valid (db-interface/hub-invalid)');
        }
    }

    async get_current_hub() {
        if(this.#current_hub == null) {
            const default_hub = await this.get_default_hub_id();
            await this.set_current_hub(default_hub).catch((error) => {
                if(default_hub) {
                    console.log(error.message);
                }
                this.clear_current_hub();
            });
        }

        return this.#current_hub;
    }

    async get_current_hub_data() {
        // await this.check_update_current_hub();
        return await this.get_hub_data(await this.get_current_hub());
    }

    async get_current_hub_name() {
        return (await this.get_current_hub_data())['name'];
    }

    async get_device_ids(hub_id:string = null) {
        let devices:string[] = [];

        if(!hub_id)
            hub_id = await this.get_current_hub();

        if(this.#hubs.has(hub_id)) {
            devices = this.#hubs.get(hub_id).get_device_ids();
        }

        return devices;
    }

    // --== Readings ==--

    async get_readings(dev_id:string, count = 0, hub_id:string = null) {
        const rdb = await this.#get_readings_collection(dev_id, hub_id);

        let rdb_asc = [...rdb].sort(
            (a, b) => (a[1].info.timestamp < b[1].info.timestamp) ? 1 : -1
        );

        return new Map(count > 0 ? rdb_asc.slice(0, count) : rdb_asc);
    }

    #filter_readings_desc<Type>(readings:Array<Type>, count:number = 0):Array<Type> {
        return count > 0 ? readings.slice(0, count) : readings;
    }

    #filter_readings_asc<Type>(readings:Array<Type>, count:number = 0):Array<Type> {
        const end = readings.length - 1;
        return count > 0 ? readings.slice(end - count, end) : readings;
    }

    async #get_readings_after(dev_id:string, stamp:Date, hub_id:string = null) {
        const readings = await this.get_readings(dev_id, 0, hub_id);
        return [...readings].filter(
            (x) => x[1].info.timestamp > stamp
        );
    }

    async #get_readings_before(dev_id:string, stamp:Date, hub_id:string = null) {
        const readings = await this.get_readings(dev_id, 0, hub_id);
        return [...readings].filter(
            (x) => x[1].info.timestamp < stamp
        );
    }

    async #get_readings_between(dev_id:string, stamp_start:Date, stamp_end:Date, hub_id:string = null) {
        const readings = await this.get_readings(dev_id, 0, hub_id);
        return [...readings].filter(
            (x) => (x[1].info.timestamp > stamp_start) && (x[1].info.timestamp < stamp_end)
        );
    }

    async get_readings_after_desc(dev_id:string, stamp:Date, count:number = 0, hub_id:string = null) {
        const valid_readings = await this.#get_readings_after(dev_id, stamp, hub_id);
        const filtered_readings = this.#filter_readings_desc(valid_readings, count);
        return new Map(filtered_readings);
    }

    async get_readings_after_asc(dev_id:string, stamp:Date, count:number = 0, hub_id:string = null) {
        const valid_readings = await this.#get_readings_after(dev_id, stamp, hub_id);
        const filtered_readings = this.#filter_readings_asc(valid_readings, count);
        return new Map(filtered_readings);
    }

    async get_readings_before_desc(dev_id:string, stamp:Date, count:number = 0, hub_id:string = null) {
        const valid_readings = await this.#get_readings_before(dev_id, stamp, hub_id);
        const filtered_readings = this.#filter_readings_desc(valid_readings, count);
        return new Map(filtered_readings);
    }

    async get_readings_before_asc(dev_id:string, stamp:Date, count:number = 0, hub_id:string = null) {
        const valid_readings = await this.#get_readings_before(dev_id, stamp, hub_id);
        const filtered_readings = this.#filter_readings_asc(valid_readings, count);
        return new Map(filtered_readings);
    }

    async get_readings_between_desc(dev_id:string, stamp_start:Date, stamp_end:Date, count:number = 0, hub_id:string = null) {
        const valid_readings = await this.#get_readings_between(dev_id, stamp_start, stamp_end, hub_id);
        const filtered_readings = this.#filter_readings_desc(valid_readings, count);
        return new Map(filtered_readings);
    }

    async get_readings_between_asc(dev_id:string, stamp_start:Date, stamp_end:Date, count:number = 0, hub_id:string = null) {
        const valid_readings = await this.#get_readings_between(dev_id, stamp_start, stamp_end, hub_id);
        const filtered_readings = this.#filter_readings_asc(valid_readings, count);
        return new Map(filtered_readings);
    }

    async #get_readings_collection(dev_id:string, hub_id:string = null) {
        let readings:Map<string,DatabaseMockReading> = null;

        if(!hub_id)
            hub_id = await this.get_current_hub();

        if(hub_id) {
            let hub = this.#hubs.get(hub_id);
            let dev = hub.get_device(dev_id);
            if(dev && dev.type == InterfaceDeviceType.ROTASENSOR) {
                readings = (dev as DatabaseMockDeviceSensor).get_readings();
            }
        }

        return readings;
    }

    async get_latest_reading(dev_id:string, hub_id:string = null) {
        const data = await this.get_readings(dev_id, 1, hub_id);
        const latest:DatabaseMockReading = data.values().next().value;

        //Return the latest event if it exists or null (instead of undefined)
        return latest ? latest : null;
    }

    async watch_readings(dev_id:string, callback:()=>void, hub_id:string = null) {
        if(hub_id == null)
            hub_id = await this.get_current_hub();

        let un_sub = null;
        let dev = await this.get_device_data(dev_id, hub_id);
        if(dev && dev.type == InterfaceDeviceType.ROTASENSOR) {
            un_sub = (dev as DatabaseMockDeviceSensor).register_watch_readings(callback);
            callback(); //TODO: Need to call device id and hub id?
        }
        return un_sub;
    }

    async watch_devices(callback:()=>void, hub_id:string = null) {
        if(hub_id == null)
            hub_id = await this.get_current_hub();

        let un_sub = null;
        let hub = await this.get_hub_data(hub_id);
        if(hub) {
            un_sub = hub.register_watch_devices(callback);
            callback(); //TODO: Need to call device id and hub id?
        }
        return un_sub;
    }

    async watch_hub_data(callback:(hub_id:string, data:InterfaceHub)=>void, hub_id:string = null) {
        if(hub_id == null)
            hub_id = await this.get_current_hub();

        let un_sub = null;
        let hub = await this.get_hub_data(hub_id);

        if(hub) {
            un_sub = hub.register_watch_data(callback);
            callback(hub_id, hub);
        }

        return un_sub;
    }

    async watch_device_data(dev_id:string, callback:(dev_id:string, data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor)=>void, hub_id:string = null) {
        if(hub_id == null)
            hub_id = await this.get_current_hub();

        let un_sub = null;
        let dev = await this.get_device_data(dev_id, hub_id);

        if(dev) {
            un_sub = dev.register_watch_data(callback);
            callback(dev_id, dev);
        }

        return un_sub;
    }

    // --== Utility ==--
    async lookup_user(id:string) {
        if (id == this.get_user_id()) {
            return this.get_user_profile();
        } else {
            //TODO
            return null;
        }
    }

    async get_hub_data(id:string) {
        let data:DatabaseMockHub = null;

        if(this.#hubs.has(id))
            data = this.#hubs.get(id);

        return data;
    }

    async get_device_data(dev_id:string, hub_id:string=null) {
        let data = null;
        const hub = await this.get_hub_data(hub_id);

        if(hub)
            data = hub.get_device(dev_id);

        return data;
    }


    async archive_hub(id:string) {
        const selecting_current_hub = await this.get_current_hub() == id;

        console.log(`Archiving hub ${id}`);
        let success = await this.update_hub_sub_data(id, {'archived': true});

        //Specific case to reset current hub
        if(success && selecting_current_hub) {
            this.clear_current_hub();
        }

        return success;
    }

    async identify_hub(id:string) {
        return this.update_hub_sub_data(id, {'identify': true});
    }

    async add_hub(hub_data:InterfaceHub) {
        const id = DBTools.generate_uid();

        if(hub_data instanceof DatabaseMockHub) {
            hub_data.set_id(id);
            this.#hubs.set(id, hub_data);
        } else {
            const hub = DatabaseMockHub.from_data(hub_data);
            hub.set_id(id);
            this.#hubs.set(id, hub);

        }

        return id;
    }

    async add_device_to_hub(hub_id:string, device_data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor) {
        let id = null;

        if(this.#hubs.has(hub_id)) {
            id = this.#hubs.get(hub_id).add_device(device_data);
        }

        return id;
    }

    async update_hub_sub_data(id:string, data:InterfaceHubKeys) {
        let success = false;

        if(this.#hubs.has(id)) {
            let hub = this.#hubs.get(id);

            if(data.admin)
                hub.admin = data.admin;
            if(data.users)
                hub.users = data.users;
            if(data.owner)
                hub.owner = data.owner;
            if(data.name)
                hub.name = data.name;
            if(data.activation_secret)
                hub.activation_secret = data.activation_secret;
            if(data.archived)
                hub.archived = data.archived;
            if(data.identify)
                hub.identify = data.identify;
            if(data.location)
                hub.location = data.location;
            if(data.date_allocated)
                hub.date_allocated = data.date_allocated;
            if(data.date_activated)
                hub.date_activated = data.date_activated;
            if(data.date_boot)
                hub.date_boot = data.date_boot;
            if(data.date_contacted)
                hub.date_contacted = data.date_contacted;
            if(data.date_updated)
                hub.date_updated = data.date_updated;
            if(data.features)
                hub.features = data.features;
            if(data.hardware)
                hub.hardware = data.hardware;
            if(data.geo_features)
                hub.geo_features = data.geo_features;

            //Trigger the data change
            hub.on_data_change();

            success = true;
        }

        return success;
    }

    async update_hub_name(id:string, name:string) {
        const success = await this.update_hub_sub_data(id, { "name": name });
        if(success)
            console.log(`Updated name for: ${id}`);

        return success;
    }

    async update_hub_owner(id:string, owner:string) {
        const success = await this.update_hub_sub_data(id, { "owner": owner });
        if(success)
            console.log(`Updated owner for: ${id}`);

        return success;
    }

    async update_hub_location(id:string, latitude:number, longitude:number) {
        const success = await this.update_hub_sub_data(id, { "location": new CosecGeoPoint(latitude, longitude) });
        if(success)
            console.log(`Updated location for: ${id}`);

        return success;
    }

    async update_hub_overlays(id:string, overlays:InterfaceHubGeoFeatures[]) {
        const success = await this.update_hub_sub_data(id, { "geo_features": overlays });
        if(success)
            console.log(`Updated geo_features for: ${id}`);

        return success;
    }

    async update_device_sub_data(hub_id:string, dev_id:string, data:InterfaceDeviceKeys) {
        let success = false;

        if(!hub_id)
            hub_id = await this.get_current_hub();

        if(this.#hubs.has(hub_id)) {
            let hub = this.#hubs.get(hub_id);
            let dev = hub.get_device(dev_id);
            if(dev) {
                if(data.archived)
                    dev.archived = data.archived;
                if(data.timestamp)
                    dev.timestamp = data.timestamp;
                if(data.uuid)
                    dev.uuid = data.uuid;
                if(data.location)
                    dev.location = data.location;
                if(data.name)
                    dev.name = data.name;

                if(dev.type == InterfaceDeviceType.ROTAFLAG) {
                    let static_dev = (dev as DatabaseMockDeviceStatic);
                    if(data.date_install)
                        static_dev.date_install = data.date_install;
                    if(data.date_review)
                        static_dev.date_review = data.date_review;
                    if(data.review)
                        static_dev.review = data.review;
                    if(data.renewal)
                        static_dev.renewal = data.renewal;
                    if(data.warranty)
                        static_dev.warranty = data.warranty;
                    if(data.lifespan)
                        static_dev.lifespan = data.lifespan;
                }

                if(dev.type == InterfaceDeviceType.ROTAMARKER_BW) {
                    let static_dev = (dev as DatabaseMockDeviceStatic);
                    if(data.date_install)
                        static_dev.date_install = data.date_install;
                    if(data.date_review)
                        static_dev.date_review = data.date_review;
                    if(data.review)
                        static_dev.review = data.review;
                    if(data.renewal)
                        static_dev.renewal = data.renewal;
                    if(data.warranty)
                        static_dev.warranty = data.warranty;
                    if(data.lifespan)
                        static_dev.lifespan = data.lifespan;
                }

                if(dev.type == InterfaceDeviceType.ROTAMARKER_RW) {
                    let static_dev = (dev as DatabaseMockDeviceStatic);
                    if(data.date_install)
                        static_dev.date_install = data.date_install;
                    if(data.date_review)
                        static_dev.date_review = data.date_review;
                    if(data.review)
                        static_dev.review = data.review;
                    if(data.renewal)
                        static_dev.renewal = data.renewal;
                    if(data.warranty)
                        static_dev.warranty = data.warranty;
                    if(data.lifespan)
                        static_dev.lifespan = data.lifespan;
                }

                if(dev.type == InterfaceDeviceType.ROTASENSOR) {
                    let sensor_dev = (dev as DatabaseMockDeviceSensor);

                    if(data.identify)
                        sensor_dev.identify = data.identify;
                    if(data.release)
                        sensor_dev.release = data.release;
                    if(data.date_boot)
                        sensor_dev.date_boot = data.date_boot;
                    if(data.date_contacted)
                        sensor_dev.date_contacted = data.date_contacted;
                    if(data.msg_type)
                        sensor_dev.msg_type = data.msg_type;
                    if(data.features)
                        sensor_dev.features = data.features;
                    if(data.thresholds)
                        sensor_dev.thresholds = data.thresholds;
                }
                //Trigger the data change
                dev.on_data_change();

                success = true;
            }
        }

        return success;
    }

    async update_device_location(hub_id:string, dev_id:string, latitude:number, longitude:number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "location": new CosecGeoPoint(latitude, longitude) });
        if(success)
            console.log(`Updated location for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_name(hub_id:string, dev_id:string, name:string) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "name": name });
        if(success)
            console.log(`Updated nickname for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_date_review(hub_id:string, dev_id:string, review:Date) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "date_review": review });
        if(success)
            console.log(`Updated review date for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_date_install(hub_id:string, dev_id:string, install:Date) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "date_install": install });
        if(success)
            console.log(`Updated install date for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_review(hub_id:string, dev_id:string, review:number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "review": review });
        if(success)
            console.log(`Updated review for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_renewal(hub_id:string, dev_id:string, renewal:number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "renewal": renewal });
        if(success)
            console.log(`Updated renewal for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_warranty(hub_id:string, dev_id:string, warranty:number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "warranty": warranty });
        if(success)
            console.log(`Updated warranty for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_lifespan(hub_id:string, dev_id:string, lifespan:number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "lifespan": lifespan });
        if(success)
            console.log(`Updated lifespan for: ${hub_id}/${dev_id}`);

        return success;
    }

    async set_device_identify(dev_id:string, hub_id:string=null) {

        const success = await this.update_device_sub_data(hub_id, dev_id, { "identify": true });
        if(success)
            console.log(`Set identify for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_threshold(dev_id:string, parameter_type:WidgetMinNomMaxType, data_type:DATASET_ID, value:number, hub_id:string = null) {
        let success = false;

        if(!hub_id)
            hub_id = await this.get_current_hub();

        if(this.#hubs.has(hub_id)) {
            let hub = this.#hubs.get(hub_id);
            let dev = hub.get_device(dev_id);
            let threshold = null;

            if(dev.type == InterfaceDeviceType.ROTASENSOR) {
                let sensor_dev = dev as DatabaseMockDeviceSensor;
                if(!sensor_dev.thresholds)
                sensor_dev.thresholds = {};

                switch(parameter_type) {
                    case WidgetMinNomMaxType.MINIMUM: {
                        if(!sensor_dev.thresholds.min)
                        sensor_dev.thresholds.min = DBTools.create_threshold();

                        threshold = sensor_dev.thresholds.min;
                        break;
                    }
                    case WidgetMinNomMaxType.NOMINAL: {
                        if(!sensor_dev.thresholds.nom)
                        sensor_dev.thresholds.nom = DBTools.create_threshold();

                        threshold = sensor_dev.thresholds.nom;
                        break;
                    }
                    case WidgetMinNomMaxType.MAXIMUM: {
                        if(!sensor_dev.thresholds.max)
                        sensor_dev.thresholds.max = DBTools.create_threshold();

                        threshold = sensor_dev.thresholds.max;
                        break;
                    }
                }
            }

            if(threshold) {
                switch(data_type) {
                    case DATASET_ID.TEMPERATURE: {
                        threshold.ambience.temperature = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.PRESSURE: {
                        threshold.ambience.pressure = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.HUMIDITY: {
                        threshold.ambience.humidity = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.VBATT: {
                        threshold.power.voltage_batt = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.VBATT: {
                        threshold.power.voltage_solar = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.ANGLE: {
                        threshold.range.angle = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.DISTADJ: {
                        threshold.range.distance_adj = value;
                        success = true;
                        break;
                    }
                    case DATASET_ID.DISTRAW: {
                        threshold.range.distance_raw = value;
                        success = true;
                        break;
                    }
                }
            }

            //Trigger the data change
            if(success)
                dev.on_data_change();

            success = threshold != null;
        }

        return success;
    }

    /*
     *  ============================================================================================
     *  Private Functions
     *  ============================================================================================
     */

    //Generators
    #hubs_generate() {
        //XXX: Random device sec
        let hub_start_lat = -27.5;
        let hub_start_lon = 153.1;
        let rand_layouts:HubDeviceLayout[] = [];

        for(let i = 0; i < 3; i++) {
            let hub_location = new CosecGeoPoint(hub_start_lat, hub_start_lon);
            hub_start_lat += -0.02
            hub_start_lon += 0.05;

            const num_devices = Math.round(Math.random()*5 + 3);
            let dev_start_lat = hub_location.latitude;
            let dev_start_lon = hub_location.longitude;
            let device_layouts:HubDeviceSetups[] = [];

            for(let j = 0; j < num_devices; j++) {
                //Do this first to make an initial offset before adding our points
                dev_start_lat += -0.01
                dev_start_lon += (Math.random()-0.5)/50
                //Add points of devices
                device_layouts.push({type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(dev_start_lat, dev_start_lon)});
            }

            rand_layouts.push({
                hub_name: null,
                hub_location: hub_location,
                devices: device_layouts,
            });
        }

        let hubs:Map<string,DatabaseMockHub> = new Map();
        for(const layout of manual_installs.concat(rand_layouts)) {
            const id = DBTools.generate_uid() + DBTools.generate_uid();
            const ascii_A = 65;
            const num_alpha = 25;
            const num_hex = 8;
            const name = `EXAMPLE-${String.fromCharCode(ascii_A+Math.random()*num_alpha, ascii_A+Math.random()*num_alpha, ascii_A+Math.random()*num_alpha)}-${String.fromCharCode(ascii_A+Math.random()*num_hex)}`
            let hub = new DatabaseMockHub(layout.hub_location, layout.devices, layout.hub_name ? layout.hub_name : name, layout.hub_owner ? layout.hub_owner : "");
            hub.geo_features = layout.hub_geo_features ? layout.hub_geo_features : [];
            hub.set_id(id);
            hubs.set(id, hub);
        }

        return hubs;
    }
}
