import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "PWR-COR-CBR";
const line_prefix = "121036-121037";

export const cobram:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "Powerlink",
    hub_location: new CosecGeoPoint(-35.991867320363916, 145.63824855827815),
    hub_geo_features: [],
    devices: [
        {name: `${line_prefix}-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-35.9920224, 145.6390492, 83.8), date_install: new Date("2024-12-02T08:10:00.000+11:00") },
        {name: `${line_prefix}-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-35.9921295, 145.639048, 83.8), date_install: new Date("2024-12-02T08:10:00.000+11:00") },
        {name: `${line_prefix}-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-35.992229, 145.6390478, 83.8), date_install: new Date("2024-12-02T08:10:00.000+11:00") },
        {name: `${line_prefix}-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-35.991969, 145.6390199, 83.8), date_install: new Date("2024-12-02T08:10:00.000+11:00") },
        {name: `${line_prefix}-5`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-35.9920687, 145.6390207, 83.8), date_install: new Date("2024-12-02T08:10:00.000+11:00") },
        {name: `${line_prefix}-6`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-35.9921731, 145.6390238, 83.8), date_install: new Date("2024-12-04T07:25:00.000+11:00") },
    ],
}
