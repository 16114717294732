import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "PWR-COR-NRK";

export const numurkah:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "Powerlink",
    hub_location: new CosecGeoPoint(-36.069730361975246, 145.4654395416895),
    hub_geo_features: [],
    devices: [
        {name: `4425-4426-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.069444, 145.4615976, 83.8), date_install: new Date("2024-12-03T09:30:00.000+11:00") },
        {name: `4425-4426-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0694804, 145.4624324, 83.8), date_install: new Date("2024-12-03T09:30:00.000+11:00") },
        {name: `4425-4426-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0694862, 145.4620123, 83.8), date_install: new Date("2024-12-03T09:30:00.000+11:00") },
        {name: `4425-4426-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0695233, 145.462844, 83.8), date_install: new Date("2024-12-03T09:30:00.000+11:00") },
        {name: `4426-4427-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0695404, 145.4636774, 83.8), date_install: new Date("2024-12-04T09:30:00.000+11:00") },
        {name: `4426-4427-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0695748, 145.4644884, 83.8), date_install: new Date("2024-12-04T09:30:00.000+11:00") },
        {name: `4426-4427-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0695815, 145.4640789, 83.8), date_install: new Date("2024-12-04T09:30:00.000+11:00") },
        {name: `4426-4427-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0696149, 145.4648796, 83.8), date_install: new Date("2024-12-04T09:30:00.000+11:00") },
        {name: `4427-4428-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0696066, 145.4656447, 83.8), date_install: new Date("2024-12-04T11:30:00.000+11:00") },
        {name: `4427-4428-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.069636, 145.4663319, 83.8), date_install: new Date("2024-12-04T11:30:00.000+11:00") },
        {name: `4427-4428-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0696446, 145.4659819, 83.8), date_install: new Date("2024-12-04T11:30:00.000+11:00") },
        {name: `4427-4428-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0696737, 145.4666658, 83.8), date_install: new Date("2024-12-04T11:30:00.000+11:00") },
        {name: `4428-4429-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-36.0696875, 145.4673667, 83.8), date_install: new Date("2024-12-04T13:00:00.000+11:00") },
    ],
}
