import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "AUS-NET-DVL";

export const devils_pinch:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "AusNet",
    hub_location: new CosecGeoPoint(-38.58191557693987, 146.3699822730442),
    hub_geo_features: [],
    devices: [
        {name: `${name_prefix}-1`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5801779,146.3693519, 83.8), date_install: new Date("2024-12-06T11:00:00.000+11:00") },
        {name: `${name_prefix}-2`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5799607,146.3692765, 83.8), date_install: new Date("2024-12-06T11:00:00.000+11:00") },
        {name: `${name_prefix}-3`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.579692,146.3691867, 83.8), date_install: new Date("2024-12-06T11:00:00.000+11:00") },
        {name: `${name_prefix}-4`, type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-38.5794364,146.3691041, 83.8), date_install: new Date("2024-12-06T11:00:00.000+11:00") },
    ],
}
