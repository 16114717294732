import { HubDeviceLayout } from "../interface_devices";
import { holsworthy } from "./transgrid_holsworthy";
import { lake_st_clair } from "./ausgrid_lake_st_clair";
import { logan_river } from "./ergon_logan_river";
import { nz_rotasense_example } from "./nz_rotasense_example";
import { buxton } from "./ausnet_buxton";
import { devils_pinch } from "./ausnet_devils_pinch";
import { foster } from "./ausnet_foster";
import { cobram } from "./powercor_cobram";
import { numurkah } from "./powercor_numurkah";
import { swanbank } from "./powerlink_swanbank";

export const manual_installs:HubDeviceLayout[] = [
    logan_river,
    lake_st_clair,
    holsworthy,
    nz_rotasense_example,
    buxton,
    devils_pinch,
    foster,
    cobram,
    numurkah,
    swanbank,
];
